.wrapper {
	text-align: center;
	margin-top: 100px;
	padding: 0 60px;

	h1 {
		width: 35%;
		margin: 0 auto;
		font-size: 36px;
		line-height: 42px;
		font-weight: 600;
		color: #3A3A3A;
	}

	.swiper {
		margin-top: 100px;

		.items {
			padding: 30px 60px;
		}
	}


	.swiper-media {
		display: none;
	}

	@media (max-width: 991px) {

		margin-top: 80px;
		padding: 0 20px;
		
		h1 {
			width: 80%;
			font-size: 30px;
		}

		.swiper {
			display: none;
		}

		.swiper-media {
			display: block;
			margin-top: 50px;

			.items {
				padding: 30px 20px;

			}
		}
	}
}