.wrapper {
	display: flex;
	align-items: center;
	gap: 25px;
	min-height: 140px;
	background: #FFFFFF;
	border-radius: 14.722px;
	box-shadow: 3px 3px 3px 3px #f0f6fa;
	padding: 15px;

	.texts {
		h1 {
			color: #0273B7;
			font-weight: 600;
		}

		p {
			color: #777777;
			font-weight: 400;
		}
	}
}