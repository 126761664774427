.wrapper {
	text-align: center;
	margin-top: 120px;

	h1 {
		width: 45%;
		margin: 0 auto;
		font-size: 36px;
		line-height: 42px;
		font-weight: 600;
		color: #3A3A3A;
	}


	.infor-wrapper {
		margin-top: 90px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
	}

	.left {
		padding: 0 60px;
		ul {
			text-align: start;
			li {	
				list-style: none;
				font-size: 26px;
				line-height: 45px;
				span {
					position: relative;
					font-weight: 400;
					bottom: 9px;

				}
			}
		}
	}

	.right {
		img {
			width: 100%;
		}
	}

	.pharmacy-button {
		padding: 13px 30px;
		font-size: 18px;
		border-radius: 10px;
		border: 1px solid #0273B7;
		background-color: #0273B7;
		color: #fff;
		text-decoration: none;
		user-select: none;
		font-weight: 400;
		cursor: pointer;
		margin-top: 120px;
		transition: all .2s linear;

		&:hover {
			background-color: transparent;
			color: #0273B7;
		}

		&:active {
			transform: scale(.95);
		}
	}


	@media (max-width: 991px) {
		margin-top: 50px;
		h1 {
			width: 100%;
			font-size: 32px;
			font-weight: bold;
		}

		.infor-wrapper {
			grid-template-columns: 1fr;
			margin-top: 40px;

			.left {
				padding: 0 20px;

				ul {
					li {
						font-size: 20px;
						font-weight: 400;
						line-height: 40px;
					}
				}
			}

			.right {
				margin-top: 30px;

				img {
					width: 100%;
				}
			}
		}

		.pharmacy-button {
			margin-top: 20px;
		}
	}
}