.wrapper {
	background-image: url("../../assets/images/background-infor.png");
	background-position: center right;
	background-repeat: no-repeat;
	height: 700px;
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;


	@media (max-width: 1920px) {
		background-image: url("../../assets/images/big-back-form.png");
	}

	@media (max-width: 1500px) {
		background-image: url("../../assets/images/background-infor.png");
	}


.items {
	padding: 0 85px;
	width: 100%;
	}

	.pharmacy {
		position: absolute;
		bottom: 8%;
		right: 5%;
	}


	@media (max-width: 991px) {
		background-position: center;


		.items {
			padding: 0 20px;

		}

		.pharmacy {
			display: none;
		}
	}
}