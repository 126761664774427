.wrapper {
	padding: 100px 60px;
	display: grid;
	grid-template-columns: 1fr 1fr;

	.left {
		display: flex;
		flex-direction: column;


		div {
			.item {
				display: flex;
				align-items: flex-start;
				padding: 30px 0;
				line-height: 30px;
				gap: 15px;
				width: 100%;

				.top {
					display: flex;
					align-items: center;
					gap: 10px;

					h3 {
						font-size: 24px;
						font-weight: 600;
					}


					i {
						color: #0273B7;
					}
				}

				p {
					font-weight: 400;
					width: 100%;
				}



				a {
					color: #0273B7;
					text-decoration: none;
					font-weight: 500;
				}
			}
		}
	}

	.right {
		padding: 40px 0;
		display: flex;
		flex-direction: column;
		gap: 30px;


		.input {
			border: 1px solid #E3E3E3;
			padding: 8px 18px;
			border-radius: 8px;
			color: #A9A9A9;
			display: flex;
			background-color: #fff;
			align-items: center;
			position: relative;
			gap: 10px;

			input {
				width: 70%;
				outline: none;
				border: none;
				color: #A9A9A9;
				padding: 10px;
			}
		}

		p {
			color: red;
			position: absolute;
			right: 15px;
			user-select: none;
		}

		.textarea {
			border: 1px solid #E3E3E3;
			display: flex;
			gap: 20px;
			align-items: flex-start;
			padding: 16px;
			color: #A9A9A9;
			border-radius: 8px;
			background-color: #fff;

			textarea {
				border: none;
				outline: none;
				color: #A9A9A9;
				resize: none;
				background-color: #fff;
				width: 100%;
			}
		}

		.button {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;


			button {
				width: max-content;
				font-size: 18px;
				padding: 12px 14px;
				border-radius: 10px;
				background-color: #0273B7;
				color: #fff;
				border: none;
				cursor: pointer;
				transition: all .2s linear;


				&:active {
					transform: scale(.96);
				}
			}
		}

	}

	@media (max-width: 991px) {
		display: flex;
		flex-direction: column-reverse;
		padding: 20px 20px;


		.left {
			div {
				.item {
					align-items: center;
					justify-content: center;
					width: 100%;

					.top {
						text-align: center;
						justify-content: center;
						align-items: center;
						width: 100%;


						h3 {
							font-size: 22px;
						}

						i {
							font-size: 22px;
						}
					}

					p {
						width: 100%;
					}

					.text {
						text-align: center;
						width: 80%;
					}
				}
			}
		}

		.right {
			.button {
				justify-content: center;
				align-items: center;
			}
		}
	}
}