.wrapper {
	text-align: center;
	margin-top: 64px;

	h1 {
		width: 35%;
		margin: 0 auto;
		font-size: 36px;
		line-height: 42px;
		font-weight: 600;
		color: #3A3A3A;
	}

	.images {
		display: grid;
		margin-top: 64px;
		grid-template-columns: repeat(4,1fr);
		justify-content: center;
		align-items: center;
		justify-items: center;


		img {
			height: 400px;
		}
	}

	.swiper-media {
		display: none;
	}
	

	@media (max-width: 991px) {
		grid-template-columns: 1fr;
		padding: 40px 0;


		.images {
			display: none;
		}

		.swiper-media {
			display: flex;
			margin: 50px auto;
			width: 100%;


			.items {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 auto;
				padding: 0 40px;

				img {
					width: 100%;
					margin: 0;
				}

				.img2 {
					position: relative;
					bottom: 11px;
				}


			}
		}

	}
}

.modal {
	display: flex;
	justify-content: center;
	align-items: center;

	div {
		padding: 10px;
	}

	.modalImg {
		width: 380px;
		height: auto;
	}
}

:global {

	.ant-modal .ant-modal-content {
		padding: 10px;
	}

	.ant-modal-footer {
		display: none;
	}

}