.wrapper {
	width: 24%;
	background-color: #fff;
	padding: 40px 30px;
	border-radius: 10px;

	.head {
		display: flex;
		flex-direction: column;
		text-align: center;
		margin: 0 auto;

		h4 {
			font-size: 24px;
			font-weight: 600;
		}

		span {
			font-size: 18px;
			font-weight: 500;
			margin: 15px;
		}
	}

	.inputs {
		display: flex;
		flex-direction: column;
		margin-top: 25px;
		gap: 10px;


		input {
			padding: 15px;
			border-radius: 8px;
			border: 1px solid #E3E3E3;
			outline: none;
		}


		p {
			color: red;
			font-size: 13px;
			margin: 0;
			padding: 0;
		}
	}

	.bottom {
		display: flex;
		flex-direction: column;
		text-align: center;
		margin-top: 20px;


		button {
			padding: 12px;
			border-radius: 8px;
			border: 1px solid #ddd;
			background: linear-gradient(90deg, #D65693 0%, #0273B7 100%);
			color: #fff;
			font-weight: 400;
			cursor: pointer;
			transition: all .2s linear;


			&:active {
				transform: scale(.97);
			}
		}

		span {
			margin-top: 15px;
			font-size: 14px;
			font-weight: 500;
			color: #0273B7;
		}
	}

	@media (max-width: 991px) {
		width: 80%;
		margin: 0 auto;
	}
}