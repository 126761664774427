.wrapper {
	margin-top: 100px;
	padding: 120px 100px;
	background-repeat: no-repeat;
	height: 70vh;
	margin-bottom: -30px;
	background-image: url("../../assets/images/background-bottom.png");
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media (max-width: 1920px) {
		background-image: url("../../assets/images/big-back-img-bottom.png");
	}

	@media (max-width: 1600px) {
		background-image: url("../../assets/images/background-bottom.png");
	}


	.texts {
		margin-top: 220px;

		h1 {
			font-size: 84px;
			font-weight: 600;
			color: #fff;
		}

		p {
			font-size: 40px;
			width: 80%;
			color: #fff;
			font-weight: 600;
			line-height: 48px;
		}
	}

	@media (max-width: 991px) {

		margin-top: 80px;
		margin-bottom: 0;
		padding: 50px 40px;
		height: max-content;

		.texts {
			display: none;
		}
	}

}