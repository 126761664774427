.wrapper {
padding: 0 60px;
display: grid;
grid-template-columns: .5fr 1fr;
gap: 30px;

.left {
	padding: 15px 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
	.img-top {
		display: flex;
		align-items: center;
		gap: 20px;

		img {
			height: 250px;
			width: 100%;
		}
	}

	.pharmacy {
		height: 280px;
	}
}

.right {
	padding: 40px 0;
	.heading {
			h5 {
				font-size: 30px;
				line-height: 40px;
				font-weight: 700;
			}
	}

	.longt-text {
		line-height: 44px;
		font-size: 21px;
		font-weight: 400;
		margin-top: 50px;
	}
}

 @media (max-width: 991px) {

	padding: 0 20px;
	display: flex;
	flex-direction: column-reverse;

		.left {
			.img-top {
				display: grid;
				grid-template-columns: 1fr 1.5fr;


				img {
					height: 200px;
				}

				.third {
					width: 100%;
				}
				.second {
					width: 100%;
				}
			}

			.pharmacy {
				height: 100%;
			}
		}


		.right {
			padding: 0;
	.heading {
		
		h5 {
			font-size: 24px;
	   }
	}

	.longt-text {
		font-size: 16px;
	}
  }
 }
}