* {
	margin: 0;
	padding: 0;
	font-family: 'Inter', sans-serif;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	
}




.banner-wrapper {
	background-image: url("./assets/images/banner.png");
	background-repeat: no-repeat;
	min-height: 100vh;
	background-size: cover;
	background-position: center;
	width: 100%;
	overflow: hidden;


	@media (max-width: 1920px) {
		background-image: url("./assets/images/bac-img-large.png");

	}


	@media (max-width: 991px) {
		background-image: url("./assets/images/back-mobile.png");
		background-position: center;
	}
}


.bottom-wrapper {
	background-image: url("./assets/images/right.png");
	background-repeat: no-repeat;
	background-position: right -180px;

	@media (max-width: 991px) {
		background-image: none;
	}
}


.bottom-wrap {
	background-image: url("./assets/images/left.png");
	background-repeat: no-repeat;
	background-position: left -180px;


	@media (max-width: 991px) {
		background-image: none;
	}
}