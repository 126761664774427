.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #0273B7;
	padding: 30px 60px;
	gap: 15px;
	color: #fff;
	font-size: 16px;

	p {
		font-weight: 400;
	}

	a {
		color: #fff;
		font-weight: 500;
	}

	.icons {
		display: flex;
		align-items: center;
		gap: 20px;
		font-size: 24px;

	}


	@media (max-width: 991px) {
		padding: 30px 10px;
		text-align: center;
	}
}

.footer-bottom {
	border-top: 1px solid #fff;
	background: #0273B7;
	padding: 12px;
	color: #fff;
	font-family: 'Gilroy-Medium';
	text-align: center;
}