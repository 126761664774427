.wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 40px;
	margin-top: 90px;
	padding: 10px 60px;


	@media (max-width: 991px) {
		grid-template-columns: 1fr;
		margin-top: 50px;
		padding: 10px 20px;
	}

}