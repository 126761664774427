.wrapper {
	width: 90%;
	padding: 30px;
	background-color: #fff;
	box-shadow: 0px 3.44186px 15.4884px rgba(2, 115, 183, 0.1);
	border-radius: 14.722px;


	.images {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 15px;
	}

	h2 {
		font-weight: 600;
	}

	p {
	font-weight: 400;
	 }

	img {
		height: 60px;
		width: 60px;
		border-radius: 50%;
	}

	@media (max-width: 991px) {
		
		width: 80%;

		.comma {
			height: 40px;
			width: 40px;
		}

		h2 {
			font-size: 20px;
		}

		p {
			font-size: 16px;
		}

		width: 80%;
		margin: 0 auto;
	}
}